<template>
  <div class="main-body">
    <div class="table-operator">
      <a-row>
        <a-col :span="12" class="line-left">
          <a-button type="primary" class="margin-right15" @click="openModal">添加栏目</a-button>
        </a-col>
      </a-row>
    </div>
    <a-table rowKey="category_id" row-class-name="category-list" :columns="columns" :data-source="treeData" v-if="treeData.length > 0" :field-names="fieldNames" :default-expand-all-rows="true">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'sort'">
          <a-input v-model:value="record.sort" @blur="save(record.sort,record.category_id)" style="width: 50%" />
        </template>
        <template v-if="column.dataIndex === 'status'">
          <span v-if="record.status == 1" style="color: #b7eb8f"> 显示 </span>
          <span v-else style="color: #ffe58f">隐藏</span>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a-button type="link" @click="addChildCategory(record)">添加子栏目</a-button>
            <a-button type="link" @click="editCategoryModal(record)">编辑</a-button>
            <a-button type="link" danger @click="deleteConfirm(record.category_id)">删除</a-button>
          </span>
        </template>
      </template>
    </a-table>
    <a-skeleton v-else />
  </div>
  <a-modal :title="formState.category_id > 0 ? '编辑栏目' : '添加栏目'" v-model:visible="visible" @ok="handleSubmit" @cancel="onCancel" @afterClose="onCancel" :width="600">
    <a-form ref="formRef" v-bind="layout" :model="formState">
      <a-tabs type="card" v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="基础信息">
          <a-form-item label="添加方式" v-if="formState.category_id == 0">
            <a-radio-group v-model:value="formState.is_batch">
              <a-radio value="0">单个添加</a-radio>
              <a-radio value="1">批量添加</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="formState.is_batch == 1" label="栏目名称" name="batch_add" extra="唯一标识留空时自动生成拼音" :rules="[{ required: true, message: '请输入栏目名称!' }]">
            <a-textarea v-model:value="formState.batch_add" placeholder="栏目名称|唯一标识" />
          </a-form-item>
          <a-form-item v-if="formState.is_batch == 0" label="栏目名称" name="category_name" :rules="[{ required: true, message: '请输入栏目名称!' }]">
            <a-input type="text" v-model:value="formState.category_name" placeholder="栏目名称" />
          </a-form-item>
          <a-form-item v-if="formState.is_batch == 0" label="唯一标识" name="code" extra="英文数字组成，留空则自动生成拼音">
            <a-input type="text" v-model:value="formState.code" placeholder="唯一标识" />
          </a-form-item>
          <a-form-item label="上级栏目" name="category">
            <a-tree-select show-search key="category_id" :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }" placeholder="请选择上级栏目" allow-clear :tree-data="treeSelectData" v-if="treeSelectData.length > 0" :field-names="fieldNames" tree-default-expand-all v-model:value="formState.parent_id">
            </a-tree-select>
          </a-form-item>
          <a-form-item label="列表展示">
            <a-radio-group v-model:value="formState.is_list">
              <a-radio value="1">列表</a-radio>
              <a-radio value="0">单页</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="链接地址" name="url">
            <a-input type="text" v-model:value="formState.url" placeholder="自定义链接地址" />
          </a-form-item>
          <a-form-item label="状态" name="status">
            <a-switch checked-children="显示" un-checked-children="隐藏" v-model:checked="formState.status" />
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="额外信息">
          <a-form-item label="列表模板" name="list_tpl">
            <a-input type="text" v-model:value="formState.list_tpl" placeholder="请输入列表模板" />
          </a-form-item>
          <a-form-item label="详情模板" name="list_tpl">
            <a-input type="text" v-model:value="formState.detail_tpl" placeholder="请输入详情模板" />
          </a-form-item>
          <a-row>
            <a-col :span="4">
              <div class="extra-layout-label">扩展字段:</div>
            </a-col>
            <a-col :span="20">

              <a-space v-for="(item, index) in formState.fields" :key="index" style="display: flex; margin-bottom: 8px" align="baseline">
                <a-form-item :rules="{
                  required: true,
                  message: '请输入字段标题',
                }">
                  <a-input v-model:value="item.field_title" placeholder="字段标题" />
                </a-form-item>
                <a-form-item :rules="{
                  required: true,
                  message: '请输入字段名',
                }">
                  <a-input v-model:value="item.field_name" placeholder="字段名" />
                </a-form-item>
                <a-form-item :rules="{
                  required: true,
                  message: '请选择数据类型',
                }">
                  <a-select v-model:value="item.field_type">
                    <a-select-option v-for="(item, index) in defaultFieldType" :value="item.value" :key="index">
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <MinusCircleOutlined @click="removeField(item)" />
              </a-space>
              <a-form-item>
                <a-button type="dashed" block @click="addField">
                  <PlusOutlined />
                  添加字段
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item label="排序" name="sort" extra="数字越小越靠前" :rules="[{ required: true, message: '请输入排序!' }]">
            <a-input-number :min="0" v-model:value="formState.sort" />
          </a-form-item>
          <a-form-item label="栏目图片">
            <CsImage v-model:imgOne="formState.thumb" />
          </a-form-item>
          <a-form-item label="栏目描述" name="description">
            <a-textarea v-model:value="formState.description" placeholder="栏目描述" />
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, ref, onMounted, reactive } from "vue";
import CsImage from "@/components/UpImage";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import * as Api from "@/views/document/api";
import { Form, message, Modal } from "ant-design-vue";
const useForm = Form.useForm;
import "./style/category.less";

export default defineComponent({
  components: {
    CsImage,
    MinusCircleOutlined,
    PlusOutlined,
  },
  setup() {
    const formRef = ref();
    const visible = ref(false);

    // 列表数据
    const treeData = ref([]);
    const treeSelectData = ref([]);

    const save = (sort, category_id) => {
      Api.csort({ sort: sort, category_id: category_id }).then((res) => {
        if (res.status === 200) {
          refreshCategoryData();
        }
      });
    };

    const refreshCategoryData = () => {
      Api.getCategoryList().then((res) => {
        treeData.value = res.data;
        let top_category = [
          {
            category_id: 0,
            category_name: "顶级栏目",
            children: [],
          },
        ];
        treeSelectData.value = top_category.concat(res.data);
      });
    };

    const formState = reactive({
      category_id: 0,
      category_name: "",
      code: "",
      parent_id: "0",
      is_list: "1",
      sort: 100,
      url: "",
      status: true,
      list_tpl: "",
      detail_tpl: "",
      is_batch: "0",
      batch_add: "",
      fields: [],
      thumb: "",
      description: "",
    });

    //添加子栏目
    const addChildCategory = (data) => {
      resetFields();
      formState.parent_id = data.category_id;
      visible.value = true;
    };

    // 编辑栏目
    const editCategoryModal = (data) => {
      resetFields();
      formState.category_id = data.category_id;
      formState.category_name = data.category_name;
      formState.code = data.code;
      formState.url = data.url;
      formState.parent_id = data.parent_id.toString();
      formState.is_list = data.is_list.toString();
      formState.sort = data.sort;
      formState.thumb = data.thumb;
      formState.description = data.description;
      formState.list_tpl = data.list_tpl;
      formState.detail_tpl = data.detail_tpl;
      formState.status = data.status ? true : false;
      formState.fields = data.fields ? data.fields : [];
      visible.value = true;
    };

    const { resetFields } = useForm(formState);

    // 添加修改栏目
    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (formState.category_id > 0) {
            // 修改
            Api.categoryUpdate(formState).then((res) => {
              visible.value = false;
              if (res.status === 200) {
                message.success(res.message);
              } else {
                message.error(res.message);
              }
              resetFields();
              refreshCategoryData();
            });
          } else {
            // 添加
            Api.categoryCreate(formState).then((res) => {
              visible.value = false;
              if (res.status === 200) {
                message.success(res.message);
              } else {
                message.error(res.message);
              }
              resetFields();
              refreshCategoryData();
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });

      // visible.value = false;
    };

    // 打开弹框
    const openModal = () => {
      resetFields();
      visible.value = true;
    };

    const addField = () => {
      formState.fields.push({
        field_title: "",
        field_name: "",
        field_type: "text",
      });
    };

    const onCancel = () => {};

    const activeKey = ref("1");

    onMounted(() => {
      refreshCategoryData();
    });

    const deleteConfirm = (category_id) => {
      Modal.confirm({
        title: "删除后将无法恢复，栏目下存在文档讲无法删除！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.categoryDelete({
            category_id: category_id,
          }).then((res) => {
            if (res.status === 200) {
              message.success(res.message);
            } else {
              message.error(res.message);
            }
            refreshCategoryData();
          });
        },
      });
    };

    const removeField = (item) => {
      let index = formState.fields.indexOf(item);

      if (index !== -1) {
        formState.fields.splice(index, 1);
      }
    };

    const defaultFieldType = [
      {
        title: "文本框",
        value: "text",
      },
      {
        title: "短文本",
        value: "short_text",
      },
      {
        title: "长文本",
        value: "long_text",
      },
      {
        title: "纯数字",
        value: "number",
      },
      {
        title: "文本域",
        value: "textarea",
      },
    ];

    return {
      visible,
      save,
      openModal,
      onCancel,
      activeKey,
      treeData,
      treeSelectData,
      formRef,
      formState,
      addChildCategory,
      editCategoryModal,
      deleteConfirm,
      handleSubmit,
      resetFields,
      layout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {},
      },
      removeField,
      addField,
      defaultFieldType,
      fieldNames: {
        key: "category_id",
        title: "category_name",
        label: "category_name",
        children: "children",
        value: "category_id",
      },
      columns: [
        {
          title: "栏目名称",
          dataIndex: "category_name",
          key: "category_name",
          width: "30%",
        },
        {
          title: "标识",
          dataIndex: "code",
          key: "code",
          width: "10%",
        },
        {
          title: "数据量",
          dataIndex: "with_info_count",
          key: "with_info_count",
          width: "10%",
        },
        {
          title: "排序",
          dataIndex: "sort",
          width: "10%",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: "20%",
          key: "action",
        },
      ],
    };
  },
});
</script>